import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../interceptor';
import endpoints from '../../constants/endpoints';
import { PageLoading } from '../../components/common/PageLoading';
import { useAuth } from '../../contexts/AuthProvider';
import { Icon } from '@iconify/react';
import useSnackbar from '../../hooks/useSnackbar';
import icons from '../../constants/icons';

const orderApi = endpoints.orders.fetchAll;
const ActionBtnContext = createContext();
const SearchContext = createContext();
const DetailContext = createContext();

const OrderLists = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const { user, loading: authorizing } = useAuth();
    const { showSnackbar } = useSnackbar();
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [showDetails, setShowDetails] = useState(null)

    const fetchOrder = async () => {
        try {
            setLoading(true);
            const response = await apiClient({
                method: orderApi.method,
                url: orderApi.path
            });
            console.log(response.data)
            setOrders(response.data);
        } catch (err) {
            console.error(err);
            showSnackbar(err?.response?.data ?? 'An error occurred', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const statusUpdated = async (orderId, newStatus) => {
        setOrders(current => {
            const newOrders = current.map(each => each._id === orderId ? { ...each, orderStatus: newStatus } : each);
            return newOrders;
        });
    };

    useEffect(() => {
        if (!authorizing && !user) {
            navigate('/collections');
        } else {
            fetchOrder();
        }
    }, [authorizing, user]);

    if (authorizing) return <PageLoading />;

    // Filter orders based on searchText and statusFilter
    const filteredOrders = orders.filter(order => {
        const matchesSearchText = order.products[0].product.title.toLowerCase().includes(searchText.toLowerCase()) ||
            order.paymentId.toLowerCase().includes(searchText.toLowerCase());
        const matchesStatusFilter = statusFilter === 'All' || order.orderStatus === statusFilter;
        return matchesSearchText && matchesStatusFilter;
    });

    return (
        user?.role === 'regular' ? navigate('/collections') : (
            <section className="container mx-auto px-4 py-8">
                <SearchContext.Provider value={{ searchText, setSearchText }}>
                    <Heading statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
                </SearchContext.Provider>
                <DetailContext.Provider value={{showDetails, setShowDetails}}>
                    <section className="py-10">
                        {
                            loading ? <PageLoading /> : (
                                <div className='space-y-3'>
                                    {
                                        filteredOrders.length ? filteredOrders.map(each => (
                                            <OrderItem
                                                key={each._id}
                                                order={each}
                                                statusUpdated={statusUpdated}
                                            />
                                        )) : <p className="text-center text-white-grey">No orders found</p>
                                    }
                                </div>
                            )
                        }
                    </section>
                    <OrderDetails />
                </DetailContext.Provider>

            </section>
        )
    );
};

const Heading = ({ statusFilter, setStatusFilter }) => {
    const { searchText, setSearchText } = useContext(SearchContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to control dropdown visibility
    const filterRef = useRef(null);

    const handleStatusSelection = (status) => {
        setStatusFilter(status);
        setIsDropdownOpen(false); // Close dropdown after selecting a status
    };

    // Click outside event handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsDropdownOpen(false); // Use the correct state setter
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setIsDropdownOpen(false); // Use the correct state setter
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setIsDropdownOpen]); // Use the correct dependency

    return (
        <div className='flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8 mb-8'>
            <h1 className="text-center text-2xl md:text-4xl font-bold text-white">
                All <span className="text-yellow">Orders</span>
            </h1>

            <div className="flex flex-col md:flex-row items-center gap-4">
                {/* Search Input */}
                <div className="relative flex items-center border border-gray-700 rounded-full bg-gray-800">
                    <input
                        className='bg-transparent text-white px-4 py-2 rounded-full outline-none placeholder-gray-400'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder='Search Orders'
                    />
                    <span className="absolute right-2">
                        <Icon icon="mdi:magnify" className="text-gray-400" />
                    </span>
                </div>

                {/* Filter Dropdown */}
                <div className="relative" ref={filterRef}>
                    <button
                        className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-full border border-gray-700"
                        onClick={() => setIsDropdownOpen((prev) => !prev)} // Toggle dropdown visibility
                    >
                        {statusFilter}
                        <Icon icon={isDropdownOpen ? "mdi:chevron-up" : "mdi:chevron-down"} className="ml-2" />
                    </button>

                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 bg-gray-800 border border-gray-700 rounded-md shadow-lg">
                            <button
                                className={`block px-4 py-2 text-sm ${statusFilter === 'All' ? 'bg-gray-700' : 'hover:bg-gray-700'} text-white`}
                                onClick={() => handleStatusSelection('All')}
                            >
                                All
                            </button>
                            <button
                                className={`block px-4 py-2 text-sm ${statusFilter === 'Processing' ? 'bg-gray-700' : 'hover:bg-gray-700'} text-white`}
                                onClick={() => handleStatusSelection('Processing')}
                            >
                                Processing
                            </button>
                            <button
                                className={`block px-4 py-2 text-sm ${statusFilter === 'Shipped' ? 'bg-gray-700' : 'hover:bg-gray-700'} text-white`}
                                onClick={() => handleStatusSelection('Shipped')}
                            >
                                Shipped
                            </button>
                            <button
                                className={`block px-4 py-2 text-sm ${statusFilter === 'Delivered' ? 'bg-gray-700' : 'hover:bg-gray-700'} text-white`}
                                onClick={() => handleStatusSelection('Delivered')}
                            >
                                Delivered
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



const OrderItem = ({ order, statusUpdated }) => {
    const processingClass = "border border-blue-500 text-blue-500";
    const shippedClass = "border border-amber-600 text-amber-600";
    const deliveredClass = "border border-green-600 text-green-600";
    const defaultClass = "border-opacity-17 text-white-grey";

    const statusClass = "flex gap-2 px-3 py-1 text-xs rounded-md bg-transparent";
    const orderStatus = order.orderStatus;

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const {setShowDetails} = useContext(DetailContext);

    const updateOrderStatus = async (status) => {
        try {
            setLoading(true);

            await apiClient({
                method: 'patch',
                url: `/orders/${order._id}/status`,
                data: { orderStatus: status }
            });

            statusUpdated(order._id, status);
            showSnackbar('Successfully updated order status.', { variant: 'success' });
        } catch (err) {
            console.log(err);
            showSnackbar(err?.response?.data ?? 'An error occurred', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    return (
        <section className='p-2 space-y-2 border border-gray-700 rounded-md bg-black-coal group'>
            <div className='flex gap-4'>
                <img
                    src={order.products[0].product.pictures[0]['file']}
                    className='w-24 h-24 rounded-md'
                    alt={order.products[0].product.title}
                />
                <div className="flex-1 space-y-2">
                    <p 
                        className='text-white text-sm group-hover:underline'
                        role='button' onClick={() => setShowDetails(order)}
                    >{order.products[0].product.title}</p>
                    <p className='text-gray-400 text-sm'>{order.paymentId}</p>
                    <div className="flex items-center gap-2">
                        <p className='text-white text-sm'>${order.totalPrice} for</p>
                        <p className='text-white text-sm'>{order.products[0].quantity} item{order.products[0].quantity > 1 ? 's' : ''}</p>
                        <div className="hidden md:flex gap-2">
                            <ActionBtnContext.Provider value={{
                                statusClass, orderStatus,
                                processingClass, defaultClass,
                                shippedClass, deliveredClass, updateOrderStatus,
                            }}>
                                <ActionButtons />
                            </ActionBtnContext.Provider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex md:hidden gap-2">
                <ActionBtnContext.Provider value={{
                    statusClass, orderStatus,
                    processingClass, defaultClass,
                    shippedClass, deliveredClass, updateOrderStatus
                }}>
                    <ActionButtons />
                </ActionBtnContext.Provider>
            </div>
        </section>
    )
}

const ActionButtons = () => {
    const {
        statusClass,
        orderStatus,
        processingClass,
        defaultClass,
        shippedClass,
        deliveredClass,
        updateOrderStatus
    } = useContext(ActionBtnContext);
    return (
        <div className="flex gap-2">
            <button
                onClick={() => updateOrderStatus('Processing')}
                className={`${statusClass} ${orderStatus === 'Processing' ? processingClass : defaultClass}`}
            >
                {orderStatus === 'Processing' ? <Icon icon="codicon:check-all" /> : 'Mark as'} Processing
            </button>
            <button
                onClick={() => updateOrderStatus('Shipped')}
                className={`${statusClass} ${orderStatus === 'Shipped' ? shippedClass : defaultClass}`}
            >
                {orderStatus === 'Shipped' ? <Icon icon="codicon:check-all" /> : 'Mark as'} Shipped
            </button>
            <button
                onClick={() => updateOrderStatus('Delivered')}
                className={`${statusClass} ${orderStatus === 'Delivered' ? deliveredClass : defaultClass}`}
            >
                {orderStatus === 'Delivered' ? <Icon icon="codicon:check-all" /> : 'Mark as'} Delivered
            </button>
        </div>
    )
}

const OrderDetails = () => {
    const {showDetails, setShowDetails} = useContext(DetailContext);

    const details = showDetails?.details;

    return (
        <section className={`modal ${showDetails? 'scale-100':'scale-0'}`}>
            {showDetails && (
                <div className="bg-black-coal w-full h-[100vh] md:w-[500px] md:h-[350px] shadow-xl rounded-xl">
                    <header className="centerRY justify-between h-[65px] border-b border-white border-opacity-[.17] p-3">
                        <h1 className='text-lg font-[Montserrat-B] text-center text-yellow'>Order Details</h1>
                        <button onClick={()=>setShowDetails(null)}>
                            <Icon icon={icons.times} className='text-xl text-white-grey' />
                        </button>
                    </header>
                    <main className='p-3 space-y-3'>
                        <p className='text-xs text-white-grey'>Ref: {showDetails.paymentId}</p>
                        <p className='text-sm text-white'>
                            <span className="text-xs text-white-grey">Contact Name:</span> {details?.contactName}
                        </p>
                        <p className='text-sm text-white'>
                            <span className="text-xs text-white-grey">Contact Number: </span>{details?.contactNumber}
                        </p>
                        <p className='text-sm text-white'>
                            <span className="text-xs text-white-grey">Address:</span> <br />
                            {details?.address}
                        </p>
                        <p className='text-sm text-white'>
                            <span className="text-xs text-white-grey">Postal Code: </span>{details?.postalCode}
                        </p>
                        <p className='text-sm text-white'>
                            <span className="text-xs text-white-grey">City, State, Country: </span> <br />
                            {details?.city}, {details?.state}, {details?.country},
                        </p>

                    </main>
                    
                </div>
            )}
        </section>
    )
}

export default OrderLists;
