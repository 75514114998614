import React from 'react';
import Collections from '../components/items/Collections';
import HeroSection from '../components/home/Hero';

export const Home = () => {


  return (
    <section className="">
      {/* Hero Section */}
      <HeroSection />

      {/* Top Collection */}
      <section className="py-10 w-full overflow-hidden mt-10">
        <Collections />
      </section>

      <div className="overflow-hidden">
        <img src='/images/cloud-ix-kaws.png' alt="" className='-mt-22 w-full object-cover h-[200px] md:h-auto' />
      </div>
    </section>
  );
};

