import React, { createContext, useContext, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import FormField from '../common/FormField';
import useSnackbar from '../../hooks/useSnackbar';
import endpoints from '../../constants/endpoints';
import apiClient from '../../interceptor';
import icons from '../../constants/icons';
import { useDispatch } from 'react-redux';
import { ItemActions } from '../../store/reducers/items';

const itemCreateApi = endpoints.items.create;
// Create a context to manage form data and image base64
const FormContext = createContext();

export default function AddProduct({ show, close }) {
    const [base64, setBase64] = useState('');
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        title: '',
        price: '',
        sizes: ''
    });

    const handleChange = ({name, value}) => {
        setForm({...form, [name]: value});
    };

    if (!show) return null;

    return (
        <div
        className={`bg-black bg-opacity-[.7] fixed top-0 left-0 w-full h-[100vh] centerRXY transition-all 
        ${show ? 'scale-100' : 'scale-0'}`}
        >
        <div className="relative w-full lg:w-[800px] px-5 py-10 md:px-10 bg-black-coal rounded-lg shadow-lg flex flex-col md:flex-row gap-4">
            <FormContext.Provider value={{ close, form, handleChange, base64, setBase64, loading, setLoading }}>
            <div className="w-full md:w-2/3 space-y-7">
                <h2 className="text-2xl font-bold text-yellow-400">New Collection</h2>
                <ItemForm />
            </div>

            {/* Preview Section */}
            <div className="flex-none w-full md:w-[250px]">
                <Preview />
            </div>
            </FormContext.Provider>

            {/* Close Button */}
            <Icon
            icon="clarity:times-line"
            className="absolute top-1 right-1 border border-white-grey border-opacity-[.17] text-3xl text-white-grey"
            role="button"
            onClick={close}
            />
        </div>
        </div>
    );
}

const ItemForm = () => {
    const fileRef = useRef();
    const {showSnackbar} = useSnackbar()
    const dispatch = useDispatch()
    const { form, handleChange, setBase64, loading, setLoading, close } = useContext(FormContext);

    // Handle file upload and convert it to base64
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
        setBase64(reader.result); // store base64 encoded image
        };
        if (file) {
        reader.readAsDataURL(file);
        }
    };

    const triggerFile = () => {
        fileRef.current.click();
    };

    const validateForm = () => {
        const file = fileRef.current.files[0];
        const { title, price, sizes } = form;
    
        if (!(title.trim() && price && sizes.trim())) {
            showSnackbar('Please fill in all required fields.', { variant: 'error' });
            return false;
        }
    
        const sizeArray = sizes.split(',')
                               .filter(each => each.trim() !== "")
                               .map(each => each.trim());
    
        if (sizeArray.length < 1) {
            showSnackbar('Shoe sizes are required in this format 43,44,45', { variant: 'error' });
            return false;  
        }
    
        if (!file) {
            showSnackbar('Product image is required', { variant: 'error' });
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        const file = fileRef.current.files[0];

        setLoading(true);

        const sizeArray = form.sizes
            .split(',')
            .filter(each => each.trim() !== "")
            .map(each => each.trim());

        const fd = new FormData();
        fd.append('title', form.title);
        fd.append('description', form.description);
        fd.append('price', form.price);
        fd.append('sizes', JSON.stringify(sizeArray));

        fd.append('files', file); // Appending the file directly

        try {
            const response = await apiClient({
                method: itemCreateApi.method,
                url: itemCreateApi.path,
                data: fd,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            dispatch(ItemActions.addItem(response.data));
            showSnackbar('Item created successfully!', { variant: 'success' });
            close(); // Close the modal
        } catch (error) {
            const errMsg = error?.response?.data ?? "An error occurred during creation.";
            showSnackbar(errMsg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

  return (
    <section className="space-y-3">
      {/* Upload Image */}
      <div
        className="bg-opacity-17 bg-black centerRXY w-full py-[10px] font-[Montserrat-M] rounded"
        role="button"
        onClick={triggerFile}
      >
        <label className="text-sm text-white-grey">Upload Image</label>
        <input ref={fileRef} type="file" className="hidden" onChange={handleImageUpload} />
      </div>

      {/* Collection Name */}
      <div className="space-y-3">
        <FormField
          label="Product Name"
          name="title"
          value={form.title}
          onChange={handleChange}
          background="bg-black"
        />

        <FormField
            label="Sizes (separated with comma)"
            name="sizes"
            value={form.sizes}
            onChange={handleChange}
            background="bg-black"
        />

        <div className="grid grid-cols-2 gap-2 items-end">
          <FormField
            label="Price $"
            placeholder="0.00"
            name="price"
            value={form.price}
            onChange={handleChange}
            background="bg-black"
          />
          {/* Save Button */}
          <div className="flex justify-end">
            <button 
                className="px-4 py-2 text-black bg-yellow rounded hover:bg-amber-400 centerRXY"
                disabled={loading}
                onClick={handleSubmit}
            >
              Save <Icon icon={loading? icons.loading : icons.plus} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Preview = () => {
  const { form, base64 } = useContext(FormContext);

  return (
    <div className="centerCX gap-2 w-[250px] h-[320px] mx-auto p-1 bg-black-coal rounded-lg border-opacity-17">
      {/* Display uploaded image or placeholder */}
      <div className="w-full h-[220px] bg-[#1E1E1E] centerRXY text-white-grey text-xs">
        {base64 ? (
          <img src={base64} alt="Uploaded preview" className="object-cover w-full h-full" />
        ) : (
          <p>No image uploaded</p>
        )}
      </div>
      <p className="text-white-grey font-[Montserrat-M] text-center text-sm mb-2">
        {form.title || 'Type collection name to preview'}
      </p>
      <p className="text-yellow-400 text-xl font-bold">${form.price || '0'}</p>
    </div>
  );
};
