import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';

export default function OnlyGuest({children, redirect}) {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && redirect) {
            navigate(redirect);
        }
    }, [user, redirect, navigate])

    return !user ? children : null;
}
