import React, { useEffect, useState } from 'react'
import icons from '../../constants/icons'
import { Icon } from '@iconify/react';
import FormField from '../common/FormField';
import endpoints from '../../constants/endpoints';
import apiClient from '../../interceptor';
import { useSelector } from 'react-redux';
import useSnackbar from '../../hooks/useSnackbar';

const intiateApi = endpoints.pay.intitiate

export default function CheckoutInfo(props) {
    const {show, close} = props
    const cart = useSelector(state => state.cart);
    const {showSnackbar} = useSnackbar()


    const [initiatingPay, setInitiatingPay] = useState(false)
    const [form, setForm] = useState({
        contactName: '',
        contactNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: ''
    })

    useEffect(() => {
      if(!show) return;

      const details = sessionStorage.getItem('checkout_details');
      if(details) {
        setForm(JSON.parse(details) ?? form)
      }

    }, [show])

    const handleChange = ({name, value}) => {
      setForm(() => {
        return {...form, [name]: value}
      })
      sessionStorage.setItem('checkout_details', JSON.stringify({...form, [name]: value}));
    }

    const closeForm = () => {
      setForm({
        contactName: '',
        contactNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: ''
      })

      close()
    }

    const initiatePayment = async() => {
        for(let key in form) {
          if(!form[key]?.trim()) {
            showSnackbar('All fields are required', {variant: 'error'})
            return;
          }
        }
    
        try {
          setInitiatingPay(true)
            const response = await apiClient({
              method: intiateApi.method,
              url: intiateApi.path,
              data: {
                amount: cart.totalPrice,
                items: JSON.stringify(cart.items.map(item => ({
                  product: item.productId,
                  quantity: item.quantity,
                  price: item.price,
                  size: item.size
                }))),
              }
            });
            window.location.href = response.data
        }catch(err) {
            console.log(err)
        }
        setInitiatingPay(false)
      }

    return (
      <div className={`
        fixed top-0 right-0 h-[100vh] bg-black bg-opacity-50 z-[1000] flex justify-end 
        overflow-hidden transition-all ${show? 'w-full':'w-0'}
      `}>
        <main className='w-full md:w-[350px] h-[100vh] bg-black'>
          <header className="h-[50px] border-b border-white border-opacity-[.17] centerRY justify-between px-3">
            <h1 className="text-sm md:text-base font-[Montserrat-SB]">Checkout</h1>
            <button onClick={closeForm}>
              <Icon icon={icons.times} className='text-white-grey text-xl' />
            </button>
          </header>
  
          <main className='px-3 py-10'>
            <div className='space-y-4'>
              <FormField
                label="Contact Name"
                name="contactName"
                value={form.contactName}
                onChange={handleChange}
              />
              <FormField
                label="Contact Number"
                name="contactNumber"
                value={form.contactNumber}
                onChange={handleChange}
              />
              <FormField
                label="Address"
                name="address"
                value={form.address}
                onChange={handleChange}
              />
              <div className="grid grid-cols-2 gap-2">
                <FormField
                  label="City"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                />
                <FormField
                  label="State"
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <FormField
                  label="Postal Code"
                  name="postalCode"
                  value={form.postalCode}
                  onChange={handleChange}
                />
                <FormField
                  label="Country"
                  name="country"
                  value={form.country}
                  onChange={handleChange}
                />
              </div>

              <button 
                className="w-full md:px-[25px] py-[8px] md:py-[10px] text-[#333] bg-yellow centerRXY rounded-md text-sm md:text-base gap-2"
                onClick={initiatePayment}
                disabled={initiatingPay}
              >
                {initiatingPay? 'Hold on...' : 'Checkout Now'}
                <Icon icon={initiatingPay? icons.loading : icons.buy} className="text-xl" />
              </button>
            </div>
          </main>
        </main>
      </div>
    )
  }
