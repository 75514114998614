import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import icons from '../constants/icons';
import { useNavigate } from 'react-router-dom';
import apiClient from '../interceptor';
import endpoints from '../constants/endpoints';
import useSnackbar from '../hooks/useSnackbar';
import FormField from '../components/common/FormField';

const fgApi = endpoints.users.forgot

export const ForgotPassword = () => {
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [state, setState] = useState(false)

    const sendLink = async() => {
        try {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            console.log(email)
            if (!emailPattern.test(email)) {
                showSnackbar("Please enter a valid email address.", { variant: 'error' });
                return false;
            }

            setLoading(true);

            await apiClient({
                method: fgApi.method,
                url: fgApi.path,
                data: { email }
            })

            // const resetToken = response.data;
            // console.log(resetToken);
            showSnackbar('Verification link has been sent successfully');
            setState(true)

        }catch(err) {
            const error = err?.response?.data ?? "An error occurred";
            showSnackbar(error, { variant: 'error' });
        }finally {
            setLoading(false);
        }
    }

    return (
        <section className="container centerRXY min-h-[70vh] pt-20 ">
            {
                !state? 
                <div className="bg-black-coal w-full md:w-[500px] min-h-[250px] shadow-xl rounded-xl p-5">
                    <h1 className="text-2xl font-[Montserrat-B] text-center py-2">
                        Forgot Password
                    </h1>
                
                    <section className="space-y-3 w-full py-3">
                        <FormField
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={({value}) => setEmail(value)}
                            background='bg-black'
                        />
                        <div className="flex items-start justify-between">
                            <SubmitButton loading={loading} click={sendLink} />
                        </div>
                        <p className='text-white-grey text-xs'>You would receive a password reset link on your mail if this email exists in our database.</p>
                    </section>
                </div> :
                <div className="bg-black-coal w-full md:w-[500px] min-h-[200px] shadow-xl rounded-xl p-5">
                    <h1 className="text-2xl font-[Montserrat-B] text-center py-2">
                        Reset Link Sent
                    </h1>
                
                    <div className="centerRXY my-10">
                        <Icon 
                            icon="clarity:success-standard-line"
                            className='text-[64px] md:text-[100px] text-yellow' 
                        />
                    </div>
                    <div className="py-2 font-[Montserrat-SB] centerRY gap-x-10">
                        <button 
                            className="centerRY gap-2 text-sm hover:underline transition"
                            onClick={() => navigate('/login')}
                        >
                            <Icon icon="bi:arrow-left" className='text-yellow text-xl' />
                            Back to Login
                        </button>
                        {/* <button className="centerRY gap-2 text-sm hover:underline transition">
                             <Icon icon={icons.notification} className='text-yellow text-xl' /> 
                            Resend
                        </button> */}
                    </div>
                </div>
            }
        </section>
    )
}


const SubmitButton = ({loading, click}) => {
    return (
        <button 
            className='w-full centerRXY gap-2 px-[12px] py-[8px] bg-yellow text-black font-[Montserrat-B] text-base rounded'
            disabled={loading}
            onClick={click}
        >
            Send Verification link <Icon icon={loading? icons.loading:icons.send} className='text-xl text-black' />
        </button>
    )
}