import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import icons from '../constants/icons';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import apiClient from '../interceptor';
import endpoints from '../constants/endpoints';
import useSnackbar from '../hooks/useSnackbar';
import FormField from '../components/common/FormField';

const resetApi = endpoints.users.resetPass

export const ResetPassword = () => {
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const resetNow = async() => {
        try {
            if (!(password.trim() && password2.trim())) {
                showSnackbar("All fields are required", { variant: 'error' });
                return false;
            }
            if (password.trim() !== password2.trim()) {
                showSnackbar("Passwords do not match", { variant: 'error' });
                return false;
            }

            setLoading(true);

            await apiClient({
                method: resetApi.method,
                url: resetApi.path,
                data: { newPassword: password, token }
            })

            showSnackbar('Password reset was successful');
            navigate('/login');

        }catch(err) {
            console.log(err)
            const error = err?.response?.data ?? "An error occurred";
            showSnackbar(error, { variant: 'error' });
        }finally {
            setLoading(false);
        }
    }

    return (
        <section className="container centerRXY min-h-[70vh] pt-20 ">
            <div className="bg-black-coal w-full md:w-[500px] min-h-[250px] shadow-xl rounded-xl p-5">
                <h1 className="text-2xl font-[Montserrat-B] text-center py-2">
                    Reset Password
                </h1>
               
                <section className="space-y-3 w-full py-3">
                    <FormField
                        label="New Password"
                        name="password"
                        value={password}
                        onChange={({value}) => setPassword(value)}
                        background='bg-black'
                        disabled={!token}
                        isPassword={true}
                    />
                    <FormField
                        label="Confirm Password"
                        name="password2"
                        value={password2}
                        onChange={({value}) => setPassword2(value)}
                        background='bg-black'
                        disabled={!token}
                        isPassword={true}
                    />
                    <div className="flex items-start justify-between">
                        <SubmitButton loading={loading} click={resetNow} disabled={!token} />
                    </div>
                </section>
            </div>
        </section>
    )
}


const SubmitButton = ({loading, click, disabled}) => {
    return (
        <button 
            className='w-full centerRXY gap-2 px-[12px] py-[8px] bg-yellow text-black font-[Montserrat-B] text-base rounded'
            disabled={loading || disabled}
            onClick={click}
        >
            Reset Password <Icon icon={loading? icons.loading:icons.send} className='text-xl text-black' />
        </button>
    )
}