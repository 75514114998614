import { createSlice } from '@reduxjs/toolkit';

// Helper function to calculate total price and quantity
const calculateTotals = (items) => {
  let totalQuantity = 0;
  let totalPrice = 0;
  items.forEach(item => {
    totalQuantity += item.quantity;
    totalPrice += item.price * item.quantity;
  });
  return { totalQuantity, totalPrice };
};

// Load cart from session storage
const loadCartFromSession = () => {
  const savedCart = sessionStorage.getItem('cart');
  return savedCart ? JSON.parse(savedCart) : [];
};

const initialState = {
  items: loadCartFromSession(), // Get items from session storage or empty
  totalQuantity: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    ...initialState,
    ...calculateTotals(initialState.items),
  },
  reducers: {
    addItem: (state, action) => {
      const item = action.payload;
      const existingItem = state.items.find(i => i.id === item.id && i.size === item.size);

      if (existingItem) {
        existingItem.quantity = item.quantity; // replace quantity
      } else {
        state.items.push({ ...item, quantity: item.quantity || 1 });
      }

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      // Persist to session storage
      sessionStorage.setItem('cart', JSON.stringify(state.items));
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      state.items = state.items.filter(item => item.id !== itemId);

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      // Persist to session storage
      sessionStorage.setItem('cart', JSON.stringify(state.items));
    },
    updateItemQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        existingItem.quantity = quantity;
        if (existingItem.quantity <= 0) {
          state.items = state.items.filter(item => item.id !== id);
        }
      }

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      // Persist to session storage
      sessionStorage.setItem('cart', JSON.stringify(state.items));
    },
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;

      // Clear session storage
      sessionStorage.removeItem('cart');
    },
  },
});

export const { addItem, removeItem, updateItemQuantity, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
