import React, { useEffect, useState, useCallback } from 'react';
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';
import { useDispatch } from 'react-redux';
import { updateItemQuantity, removeItem } from '../../store/reducers/cart';
import useSnackbar from '../../hooks/useSnackbar';

export default function CartItem({ item }) {
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const [quantity, setQuantity] = useState(item.quantity);

  const increaseQuantity = () => {
    setQuantity(prev => prev + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(prev => prev - 1);
    }
  };

  // Use useCallback to prevent function from being recreated on each render
  const handleRemoveItem = useCallback(() => {
    dispatch(removeItem(item.id)); // Dispatch remove action
    showSnackbar('Item removed from cart');
  }, [dispatch, item.id, showSnackbar]);

  useEffect(() => {
    setQuantity(parseInt(item.quantity, 10)); // Initialize quantity from the item prop
  }, [item.quantity]);

  useEffect(() => {
    if (quantity < 1) {
      setQuantity(1); // Ensure minimum quantity is 1
    }

    // Dispatch update quantity action whenever quantity changes
    dispatch(updateItemQuantity({ id: item.id, quantity }));
  }, [quantity, item.id, dispatch]);

  return (
    <div className="bg-black-coal text-white p-2 rounded-lg shadow-lg w-full h-[120px] md:h-[140px] relative">
      <div className="flex gap-2">
        {/* Image section */}
        <img
          src={item.image}
          alt=''
          className="w-[90px] md:w-[120px] h-[100px] md:h-[120px] rounded-lg object-cover"
        />
        {/* Content section */}
        <div className="grow flex-1 pr-5 md:pr-0">
          <h2 className="text-sm md:text-lg font-semibold">{item.title}</h2>
          <div className="flex-col items-start gap-x">
            <p className="text-yellow-400 text-sm md:text-xl font-[Montserrat-SB] mt-2 centerRY gap-3">
                <button
                    className="w-[35px] h-[35px] text-white-grey font-[Montserrat-SB] text-sm rounded shadow-xl bg-black centerRXY"
                >
                    {item.size}
                </button>
                ${item.price}
            </p>
            {/* Quantity selector */}
            <div className="flex items-center mt-2">
              <button 
                className="bg-white text-blue w-[22px] h-[22px] rounded centerRXY text-xl"
                onClick={decreaseQuantity}
              >
                -
              </button>
              <span className="mx-2">{quantity}</span>
              <button 
                className="bg-blue text-white w-[22px] h-[22px] rounded centerRXY text-xl"
                onClick={increaseQuantity}
              >
                +
              </button>

              {/* Total price calculation using current quantity */}
              <span className="centerRY ml-2 text-white text-sm md:text-xl font-[Montserrat-SB]">
                = ${(item.price * quantity).toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        {/* Remove item button */}
        <div className="absolute top-2 right-2">
          <button className="bg-gray-700 p-2 rounded-full group" onClick={handleRemoveItem}>
            <Icon
              icon={icons.bin}
              className="w-4 md:w-6 h-4 md:h-6 text-gray-400 group-active:text-red-500"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
