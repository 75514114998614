import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiClient from '../../interceptor';
import endpoints from '../../constants/endpoints';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../store/reducers/cart';

const payApi = endpoints.pay.complete

export const OrderSuccess = () => {
    const navigate = useNavigate()
    const [params] = useSearchParams();
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)

    
    useEffect(() => {
        const paymentId = params.get('paymentId')
        const payerId = params.get('PayerID')

        executeOrder(paymentId, payerId)
    }, [params])

    const executeOrder = async(paymentId, payerId) => {
        if(!(paymentId && payerId)) return;

        try {
            setLoading(true)
            let details = sessionStorage.getItem('checkout_details');
            
            const response = await apiClient({
                method: payApi.method,
                url: payApi.path,
                data: {
                    paymentId, 
                    payerId,
                    details
                }
            })
            setError(null)
            console.log(response.data)

            // Clear cart
            dispatch(clearCart())

        }catch(err) {
            setError(err?.response?.data ?? 'Verification failed')
            console.log(err)
        }finally {
            setLoading(false)
        }
    }

    return (
        <section className="container centerRXY min-h-[70vh] pt-20 ">
            <div className="bg-black-coal w-full h-[100vh] md:w-[500px] md:h-[350px] shadow-xl rounded-xl p-5">
                <h1 className={
                    `text-2xl font-[Montserrat-B] text-center py-2 
                    ${error? 'text-red-500':'text-yellow'}`
                }>
                    {
                        !error? loading? 'Please wait...' : 'Order Successful!' : error
                    }
                </h1>
                <div className="centerRXY my-10">
                    <Icon 
                        icon={loading? icons.loading : !error? "clarity:success-standard-line":"clarity:error-line"} 
                        className={`text-[64px] md:text-[150px] ${error? 'text-red-500':'text-yellow'}`} 
                    />
                </div>
                {
                    loading ?
                    <div className='text-xs opacity-[.8] centerRXY gap-2'>
                        <Icon icon={icons.solidInfo} className='text-white-grey text-xl' /> 
                        Please hold on while we complete your transactions
                    </div> :
                    <div className="py-2 font-[Montserrat-SB] centerRY gap-x-10">
                        <button 
                            className="centerRY gap-2 text-sm hover:underline transition"
                            onClick={() => navigate('/collections')}
                        >
                            <Icon icon="bi:arrow-left" className='text-yellow text-xl' />
                            Continue Shopping
                        </button>
                        <button 
                            className="centerRY gap-2 text-sm hover:underline transition"
                            onClick={() => navigate('/order/my-orders')}
                        >
                            <Icon icon={icons.buy} className='text-yellow text-xl' />
                            My Orders
                        </button>
                    </div>
                }
            </div>
        </section>
    )
}
