import React, { useState } from 'react'
import CartItem from '../components/order/CartItem'
import { useSelector } from 'react-redux'
import CheckoutInfo from '../components/order/CheckoutInfo'

export const Header = ({itemsCount}) => {
    const totalPrice = useSelector(state => state.cart.totalPrice)
    const [checkout, setCheckout] = useState(false);
    
    return <>
      <header className='centerRY justify-between md:gap-y-2 font-[Montserrat-B]'>
        <h1 className='title text-yellow text-xl md:text-[45px] text-center md:text-left'>
          Cart Items
        </h1>

          {
            itemsCount > 0 && (
              <button 
                className='centerRXY gap-1 px-[15px] py-[12px] rounded-xl bg-black-coal text-sm font-[Montserrat-SB] border-opacity-17 text-white text-opacity-[.75]'
                onClick={() => setCheckout(true)}
              >
                Checkout <span className="text-yellow">${totalPrice}</span>
              </button>
            )
          }
      </header>

      <CheckoutInfo show={checkout} close={() => setCheckout(false)} />
    </>
}
  
export default function Cart() {
    const cart = useSelector(state => state.cart);
    
    return (
        <section className="container">
            <Header itemsCount={cart.totalQuantity} />

            <main className="py-5 space-y-3">
                {
                    cart.items.length > 0 && cart.items.map(item => <CartItem key={item.id} item={item} />)
                }
                
            </main>
        </section>
    )
}
