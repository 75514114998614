import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import FormField from '../components/common/FormField';
import { gsap } from 'gsap';
import icons from '../constants/icons';
import useSnackbar from '../hooks/useSnackbar';
import apiClient from '../interceptor';

const Contact = () => {
  const { showSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  })

  const clearForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: ''
    })
  }

  const formIsValid = () => {
    const required = {...form};
    delete required.subject;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let invalids = 0;

    for(let key in required) {
      if(!required[key].trim()) {
        invalids += 1;
        document.getElementsByName(key)[0].classList.add('border-2', 'border-red-800');
      }else {
        if (key === 'email' && !emailRegex.test(form[key])) {
          invalids += 1;
          document.getElementsByName(key)[0].classList.add('border-2', 'border-red-800');
          showSnackbar('Please enter a valid email address', { variant: 'error' });
        } else {
          document.getElementsByName(key)[0].classList.remove('border-2', 'border-red-800');
        }
      }
    }

    return invalids === 0
  }

  const submitForm = async() => {
    try {
      const {firstName, lastName, email, message, subject} = form;
      const isValid = formIsValid();

      if(!isValid) {
        showSnackbar('Please fill in all required fields', {variant: 'error'});
        return;
      }

      setLoading(true)
      const fd = new FormData();
      fd.append('fullName', `${firstName.trim()} ${lastName.trim()}`)
      fd.append('email', email.trim())
      fd.append('subject', subject.trim())
      fd.append('message', message.trim())

      const response = await apiClient({
        method: 'post',
        url: '/contact',
        data: fd
      })

      clearForm()
      showSnackbar('Message was sent successfully', {variant: 'success'});


    }catch(err) {
      console.log(err)
      showSnackbar('Unable to send message', {variant: 'error'})
    }finally {
      setLoading(false);
    }
  }

  const handleChange = ({name, value}) => {
    setForm(prev => {
      return {...prev, [name]: value}
    })
  }

  useEffect(() => {
    const tl = gsap.timeline()

    tl.fromTo(['.title', '.sub-text'], 
      {
        y: -20,
        x: -10,
        opacity: 0,
        duration: .05,
        ease: 'back.inOut',
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: .3
      }
    )
    .fromTo('.contact-group',
      {
        y: -20,
        x: -10,
        opacity: 0,
        duration: .05,
        ease: 'power2.inOut',
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: .3
      }
    )
    .fromTo('.form-group',
      {
        y: -20,
        x: -10,
        opacity: 0,
        duration: .05,
        ease: 'back.inOut',
      },
      {
        x: 0,
        y: 0,
        opacity: 1
      }
    )
    .fromTo('.send-btn',
      {
        x: 100,
        opacity: 0,
        duration: .05,
        ease: 'power2.inOut',
      },
      {
        x: 0,
        opacity: 1,
        stagger: .3
      }
    )

    return () => {
      tl.kill()
    }
  }, [])

  return <div className='container'>
    <Header />
    <section className="grid grid-cols-1 md:grid-cols-2 md:mt-5 gap-2">
      <section className='md:space-y-7'>
        <div className='py-7 md:py-10 space-y-5'>
          <ContactGroup 
            icon="mdi:address-marker" 
            title="Address"
            text="123 Maple Street Toronto, ON M4B 1B3 Canada"
          />
          <ContactGroup 
            icon="material-symbols-light:call" 
            title="Contact Number"
            text="+234-XXX-XX-000, +234-XXX-00-XXX"
          />
          <ContactGroup 
            icon="material-symbols-light:mail" 
            title="Email Address"
            text="contact@waybaq.com"
          />
        </div>

        <span className="hidden md:inline">
          <SendButton submitForm={submitForm} loading={loading} />
        </span>
        
      </section>

      <main className='space-y-3'>
        <div className="grid grid-cols-2 gap-3">
          <FormField 
            label="First Name" 
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
          />
          <FormField 
            label="Last Name" 
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
          />
        </div>
        <FormField 
          label="Email Address" 
          name="email"
          value={form.email}
          onChange={handleChange}
        />
        <FormField 
          label="Subject" 
          name="subject"
          value={form.subject}
          optional={true}
          onChange={handleChange}
        />
        <FormField 
          label="Please enter your message" textarea={true} 
          name="message"
          value={form.message}
          onChange={handleChange}
        />
        <div className="centerRY justify-end md:hidden">
          <SendButton submitForm={submitForm} loading={loading} />
        </div>
      </main>
    </section>
  </div>

}

export const Header = () => {
  return (
    <header className='flex flex-col md:gap-y-2 font-[Montserrat-B]'>
      <h1 className='title text-yellow text-[38px] md:text-[45px] xl:text-[96px] xl:leading-[93px] text-center md:text-left'>
        Contact Us
      </h1>
      <p className='sub-text text-white text-base md:text-[32px] text-center md:text-left font-[Montserrat-EL] md:font-[Montserrat-B]'>
        Make an inquiry today!
      </p>
    </header>
  )
}

const SendButton = ({loading, submitForm}) => {
  return (
    <button 
      className='send-btn centerRXY gap-2 px-[12px] py-[8px] bg-yellow text-black font-[Montserrat-B] text-sm rounded-xl'
      disabled={loading}
      onClick={submitForm}
    > Send Message <Icon 
        icon={loading? icons.loading : "lets-icons:send-duotone"} 
        className='text-xl text-black' 
      />
    </button>
  )
}

export const ContactGroup = ({icon, title, text}) => {
  return (
    <section className='flex flex-col gap-y-1 contact-group'>
      <div className="centerRY gap-1 font-[Montserrat-M]">
        <Icon icon={icon} className='text-sm text-white' />
        <span className='text-xs text-white-grey opacity-[.8]'>{title}</span>
      </div>
      <div className='font-[Roboto-L] text-sm text-white opacity-[.76]'>{text}</div>
    </section>
  )
}

export default Contact