import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import './layout.css'
import { Icon } from '@iconify/react';
import { SnackbarProvider } from 'notistack'
import { useAuth } from '../contexts/AuthProvider';
import icons from '../constants/icons';
import OnlyAdmin from '../middleware/OnlyAdmin';
import OnlyRegular from '../middleware/OnlyReular';
import AnyButAdmin from '../middleware/AnyButAdmin';
import { gsap } from "gsap";
import OnlyGuest from '../middleware/OnlyGuest';
import OnlyAuthUser from '../middleware/OnlyAuthUser';


export const DefaultLayout = () => {
  const [mobile, setMobile] = useState(false);
  const location = useLocation()

  useEffect(() => {
    setMobile(false)
  }, [location])

  return (
    <SnackbarProvider>
      <div className='min-h-[100vh] w-[100vw] relative pb-[80px]'>
          <DefaultHeader openNav={() => setMobile(true)} />
          <MobileNav show={mobile} close={() => setMobile(false)} />

          <div className="w-full overflow-hidden">
            <Outlet />
          </div>

        <section className="absolute bottom-2 left-0 w-full h-[60px] pb-2">
          <footer className="container centerCXY md:centerRXY gap-2 justify-between">
            <Link to="/">
              <img src={require("../assets/images/logo.png")} alt="" className='h-[25px] md:h-[56px] w-auto' />
            </Link>
            <div className="centerRY gap-2 text-neutral-200 text-xs md:text-sm">
              <span className='flex items-center'>
                <Icon icon="solar:copyright-outline" className='md:text-xl mr-[.5px]' />
                2024. waybaq. All Right Reserved.
              </span>
              <a target="_blank" role='button' href="https://www.instagram.com/waybaq_official?igsh=ZG1seGpvdGp2anM2&utm_source=qr">
                <Icon icon="ri:instagram-fill" className='text-xl' />
              </a>
              <a target="_blank" role='button' href="https://www.tiktok.com/@waybaq?_t=8q2nBK1SnDi&_r=1">
                <Icon icon="akar-icons:tiktok-fill" className='text-xl' />
              </a>
            </div>
          </footer>
        </section>
      </div>
    </SnackbarProvider>
  )
}


const DefaultHeader = ({openNav}) => {
  const navigate = useNavigate()
  const {user, logout} = useAuth()

  useEffect(() => {
    const tl = gsap.timeline();

    tl.fromTo('.nav-link', 
      {
        opacity: .5,
        y: -30,
        x: -5,
        duration: 1.5,
        ease: 'power2.inOut'
      }, 
      {
        opacity: 1,
        y: 0,
        x: 0,
        stagger: 0.15
      }
    )
  }, [])

  return (
        <header className='w-full h-[100px] flex items-center bg-black'>
            <div className="container flex items-center justify-between">
              <Link to="/">
                <img 
                  src={require("../assets/images/logo.png")} 
                  alt="" className='h-[36px] w-auto nav-link' 
                />
              </Link>

              <nav className='nav-links'>
                <OnlyAdmin>
                  <NavLink to="/admin/products" className="nav-link">All Products</NavLink>
                  <NavLink to="/admin/orders" className="nav-link">Orders</NavLink>
                </OnlyAdmin>
                
                <AnyButAdmin>
                    <NavLink to="/" className="nav-link">Home</NavLink>
                    <NavLink to="/about-us" className="nav-link">About Us</NavLink>
                    <NavLink to="/contact-us" className="nav-link">Get in touch</NavLink>
                    <NavLink to="/cart" className="nav-link">My Cart</NavLink>

                    <OnlyRegular>
                      <NavLink to="/order/my-orders" className="nav-link">My Orders</NavLink>
                    </OnlyRegular>

                </AnyButAdmin>
           
              </nav>

              <nav className="flex items-center gap-3">
                <OnlyRegular>
                  <Icon 
                    icon="ion:cart" 
                    className='text-2xl text-white-grey md:hidden' 
                    onClick={() => navigate('/cart')}
                    role='button'
                  />
                </OnlyRegular>

                <OnlyGuest>
                  <button className='nav-login hidden md:flex' onClick={() => navigate('/login')}>
                    Login <Icon icon="majesticons:login" className='text-xl text-white-grey' />
                  </button> 
                </OnlyGuest>

                <OnlyGuest>
                  <button className='nav-login flex md:hidden' onClick={() => navigate('/cart')}>
                    Cart <Icon icon="ion:cart" className='text-xl text-white-grey' />
                  </button> 
                </OnlyGuest>

                <OnlyAuthUser>
                  <div className="flex items-center gap-2 md:border-opacity-17 rounded">
                    {
                      user && <button className='text-sm text-white-grey px-[8px] py-[6px] shrink-0 flex'>
                        <span className="ellipsis max-w-[50px]">{user.firstName}</span>
                        <span>.&nbsp;{user?.lastName?.charAt(0)}</span>
                      </button>
                    }
                    <button className='nav-login no-border hidden md:flex' onClick={()=>logout()}>
                      Logout <Icon icon={icons.logout} className='text-xl text-white-grey' />
                    </button>
                  </div>
                </OnlyAuthUser>
                
                <Icon 
                  icon="iconamoon:menu-kebab-vertical-bold" 
                  className='nav-menu border-opacity-17 h-[38px] w-[30px]' 
                  role='button'
                  onClick={openNav}  
                />
              </nav>
            </div>
        </header>
    )
}

const MobileNav = ({close, show}) => {
  const navigate = useNavigate();
  const { logout } = useAuth()

  return (
    <nav className={
      `w-full h-[100vh] bg-black fixed top-0 left-0 z-[1000] transition-all
      ${show? 'translate-x-0':'-translate-x-[100%]'}
    `}>
      <header className='centerRY justify-between p-3 py-5'>
        <img src={require("../assets/images/logo.png")} alt="" className='h-[36px] w-auto' />
        <Icon 
          icon={icons.times} 
          className='text-2xl border-opacity-17' 
          role='button' 
          onClick={close}
        />
      </header>

      <div className="flex flex-col items-center gap-2 py-10 gap-y-10">
        <nav className='flex flex-col items-center text-center text-white-grey gap-y-10'>
          <AnyButAdmin>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about-us">About Us</NavLink>
            <NavLink to="/contact-us">Get in touch</NavLink>
            <NavLink to="/cart">My Cart</NavLink>

              <OnlyRegular>
                <NavLink to="/order/my-orders" className="nav-link">My Orders</NavLink>
              </OnlyRegular>

          </AnyButAdmin>
          <OnlyAdmin>
            <NavLink to="/admin/products">All Products</NavLink>
            <NavLink to="/admin/orders">Orders</NavLink>
          </OnlyAdmin>
        </nav>

        <nav className="flex flex-col">
          <OnlyGuest>
            <button className='nav-login' onClick={() => navigate('/login')}>
              Login <Icon icon="majesticons:login" className='text-xl text-white-grey' />
            </button>
          </OnlyGuest>
          <OnlyAuthUser>
            <button className='nav-login logout' onClick={logout}>
              Logout <Icon icon={icons.logout} className='text-xl text-white-grey' />
            </button>
          </OnlyAuthUser>
        </nav>
      </div>
    </nav>
  )
}