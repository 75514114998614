import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';

export default function AnyButAdmin({children, redirect}) {
    const { user } = useAuth();
    const navigate = useNavigate();
    
    
    useEffect(() => {
        const isAdmin = user?.role === 'agba'; // Not to be visible to Admin
        if (isAdmin && redirect) {
            navigate(redirect);
        }
    }, [user, redirect, navigate])

    return user?.role === 'agba' ? null : children;
}
