import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';

export default function OnlyAdmin({children, redirect}) {
    const { user } = useAuth();
    const navigate = useNavigate();

    
    useEffect(() => {
        const isAdmin = user?.role === 'agba';
        
        if (!isAdmin && redirect) {
            navigate(redirect);
        }
    }, [user, redirect, navigate])

    return user?.role === 'agba' ? children : null;
}
