import React from 'react'
import { Icon } from '@iconify/react';
import icons from '../../constants/icons';

export default function RemoveItem({item, show, close, remove, deleting}) {
    return (
        <section className={`modal ${show? 'scale-100':'scale-0'}`}>
            <section className="w-full md:w-[350px] min-h-[100px] bg-black rounded shadow-xl">
                <header className="centerRY justify-between p-3 border-b border-white-grey border-opacity-[.17]">
                    <h1 className="text-base text-white-grey font-[Montserrat-M]">Confirm Delete</h1>
                    <Icon
                        icon={icons.times}
                        className="text-xl text-white-grey cursor-pointer"
                        onClick={close}
                    />
                </header>
                <main className="p-3 space-y-3">
                    <p className="text-xs md:text-sm font-[Montserrat-L]">Are you sure you want to delete the item <strong>{item.title}</strong></p>
                    <div className="centerRY justify-end gap-3">
                        <button 
                        className="px-[12px] py-[6px] border border-white-grey border-opacity-[.2] text-xs text-white-grey rounded hover:bg-black-coal"
                        onClick={close}
                        >Cancel</button>
                        <button 
                        className="centerRY gap-1 px-[12px] py-[6px] border border-red-500 border-opacity-[.2] text-xs text-red-500 rounded hover:bg-black-coal"
                        onClick={remove}
                        >Delete {deleting && <Icon icon={icons.loading} className="text-white-grey" />}</button>
                    </div>
                </main>
            </section>
        </section>
    )
}
