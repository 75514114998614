import React from 'react'

export default function ProductSkeleton() {
    return (
        <div className='w-full h-[300px] sm:h-[380px] md:h-[420px] xl:h-[475px] bg-black-coal p-1 border border-gray-600 border-opacity-50 rounded-xl flex flex-col animate-pulse'>
            <div className='flex-none h-[205px] sm:h-[280px] md:h-[300px] xl:h-[350px] rounded-t-xl bg-neutral-800'></div>
            <div className="grow p-3 md:p-5 font-[Montserrat-M] space-y-2">
                <div className='h-4 bg-neutral-800 rounded w-3/4 mx-auto'></div>
                <div className='h-6 bg-neutral-800 rounded w-1/2 mx-auto'></div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full p-3 bg-[#00000059] flex justify-end gap-5 scale-0 group-hover:scale-100 transition">
                <div className="bg-neutral-800 rounded-full w-8 h-8"></div>
                <div className="bg-neutral-800 rounded-full w-8 h-8"></div>
                <div className="bg-neutral-800 rounded-full w-8 h-8"></div>
            </div>
        </div>
    );
}
