import React, { createContext, useEffect, useRef } from 'react';
import gsap from 'gsap';
import ItemCard from '../common/ItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../../store/reducers/items';
import ProductSkeleton from './ProductSkeleton';
import { useLocation } from 'react-router-dom';


export default function Collections() {
  const collectionSectionRef = useRef(null);
    const collectionRef = useRef([]);
    const dispatch = useDispatch();
    const location = useLocation()
    const { data, error, loading } = useSelector((state) => state.items);


    // Scroll to #collection if it's found in the URL
    useEffect(() => {
      if (location.hash === '#collections') {
        collectionSectionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, [location]);

    useEffect(() => {
      dispatch(fetchItems());
      
      gsap.fromTo(
        collectionRef.current,
        {
          opacity: 0,
          y: -80,
          x: -10,
          ease: 'power1.inOut',
        },
        {
          duration: .5, // slightly longer for smoother effect
          y: 0,
          x: 0,
          opacity: 1,
          delay: .2,
          stagger: .2, // delay between each animation
          ease: 'sine.out', // smooth easing for smoother animations
          orce3D: true
        }
      );
    }, [dispatch]);

    return (
      <section className='container space-y-5' id="collections" ref={collectionSectionRef}>
        <Heading />

        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 xl:gap-5">
          {
            loading? <>
              <ProductSkeleton />
              <ProductSkeleton />
              <span className="hidden md:inline">
                <ProductSkeleton />
              </span>
            </> :
            data.map((each, index) => (
              <ItemCard 
                item={each} 
                key={index} 
                ref={(el) => (collectionRef.current[index] = el)} 
              />
            ))
          }
        </div>
      </section>
    );
  };

const Heading = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-2 text-2xl md:text-4xl font-[Montserrat-B] md:py-5'>
      <h1 className="text-center">Top <span className="text-yellow">Collections</span></h1>
      <div className="h-[4px] bg-white w-[40px] rounded-full"></div>
    </div>
  );
};
