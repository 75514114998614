import { configureStore } from '@reduxjs/toolkit'
import items from './reducers/items'
import cart from './reducers/cart'


export const store = configureStore({
  reducer: {
    items,
    cart,
  },
})