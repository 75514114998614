import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { DefaultLayout } from './layouts/DefaultLayout';
import { Home } from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { AuthProvider } from './contexts/AuthProvider';
import AdminProducts from './pages/admin/Products';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { OrderSuccess } from './pages/order/OrderSuccess';
import { OrderCancelled } from './pages/order/OrderCancelled';
import { MyOrder } from './pages/order/MyOrder';
import OrderLists from './pages/admin/Orders';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Checkout } from './pages/order/Checkout';
import Cart from './pages/Cart';


const AppRouter = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/order/checkout" element={<Checkout />} />
            <Route path="/order/my-orders" element={<MyOrder />} />
            <Route path="/order/success" element={<OrderSuccess />} />
            <Route path="/order/cancelled" element={<OrderCancelled />} />
            <Route path="/admin" element={<AdminProducts />} />
            <Route path="/admin/products" element={<AdminProducts />} />
            <Route path="/admin/orders" element={<OrderLists />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}
function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
