import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Hero() {
  const navigate = useNavigate();

  const shopNow = () => {
    navigate('/#collections')
  }

  return (
    <section className="container grid grid-cols-1 md:grid-cols-2 h-[580px] md:h-[600px] overflow-x-hidden">
        <main className='md:py-[80px] space-y-2 md:space-y-7'>
          <h1 className='lead font-[Montserrat-Bl] text-white max-w-[96px] text-[45px] leading-[52px] md:text-[75px] md:leading-[70px] xl:text-[86px] xl:leading-[82px]'>
            Experience Maximum Comfort
          </h1>
          <p className='text-sm md:text-sm xl:text-[32px] font-[Montserrat-M] text-white opacity-[.74] mt-3 md:mt-10 mb-2 md:mb-7 desc'>
            Customize to your desired design!
          </p>
          <div className="centerRY font-[Montserrat-M] gap-x-5">
            <button className='px-[15px] md:px-[18px] py-[8px] md:py-[10px] bg-gradient-to-r from-[#333787] to-[#5A5EB1] rounded-full md:rounded-md text-xs md:text-base hero-btn' onClick={shopNow}>
              Shop Now
            </button>
            <button className='px-[15px] md:px-[18px] py-[8px] md:py-[10px] bg-white text-[#333787] rounded-full md:rounded-md text-xs md:text-base hero-btn' onClick={() => navigate('/contact-us')}>
              Customize
            </button>
          </div>
        </main>
        <div className='centerRXY md:centerRY pt-[20px]'>
          <img 
            src="/images/hero-img.png" alt="" 
            className='w-auto h-[250px] md:w-[80%] md:h-auto scale-[1.2] md:mt-0 md:scale-100' 
          />
        </div>
    </section>
  )
}
